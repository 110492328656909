// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D1Xi7PxAmf3hjs9Udsn1:hover{color:#fff;background:var(--color-secondary-darker) !important}.vkU9GMkIUMjr1NP6oVoQ{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/action-on-items/SelectItemsBar/styles.module.sass"],"names":[],"mappings":"AACE,4BACE,UAAA,CACA,mDAAA,CACJ,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".registerBtn\n  &:hover\n    color: #fff\n    background: var(--color-secondary-darker) !important\n.selectAssetsBar\n  display: flex\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerBtn": `D1Xi7PxAmf3hjs9Udsn1`,
	"selectAssetsBar": `vkU9GMkIUMjr1NP6oVoQ`
};
export default ___CSS_LOADER_EXPORT___;
